const common = {
  buttons: {
    viewSteps: 'View steps',
    checkHere: 'Check here',
    markAsComplete: 'Mark as complete',
    accessibility: 'Accessibility',
    decreaseTextSize: '- Decrease text size',
    increaseTextSize: '+ Increase text size',
    benefitCalculator: 'Benefit Calculator',
    stuckCallUs: 'Stuck? Call us for advice',
    seeMore: 'See more',
    seeLess: 'See less',
    addColourOverlay: 'Add colour overlay',
  },
  generalSentence: {
    ThisCanIncludeThingsLike: 'This can include things like:',
  },
  words: { and: 'and', completed: 'Completed' },
  heading: {
    howLongDoesItTake: { title: 'How long does it take?' },
    thingsYouWillNeed: {
      title: "Things you'll need",
      text: "You don't need to provide any physical documents for this step.",
    },
    whatYouWillNeedToKnow: { title: "What you'll need to know" },
    whereDoYouNeedToGo: {
      title: 'Where do you need to go?',
      text: "Remember to come back here once you're done so you can mark this step as complete and see what to do next",
    },
    tip: 'Tip!',
  },
  section: {
    changeLanguage: {
      title: 'Change language',
      placeholder: 'Search',
    },
    afterClaimContent: {
      title: {
        completed: 'You’re all done!',
        notCompleted: `What should I do once I am granted Universal Credit?`,
      },
      text: {
        completed: `Got your Universal Credit? Great news! Check out these steps on what to do next:`,
        notCompleted: `Once you’ve completed your claim there are few additional steps you can take. Open this when you’ve completed the above steps`,
      },
    },
    helpMe: {
      title: 'Help!',
      subtitle: 'Help is here!',
      description:
        'We all need to speak to someone sometimes! Use any of the contact details below to find a person to chat with.',
      govHelpline: 'Government Helpline',
      govOpeningTimes: 'Monday to Friday, 8am to 6pm',
      govPhone: '0800 328 5644 (choose Option 3)',
    },
    accessibility: {
      title: 'Accessibility',
      description1:
        'Accessibility on this website is guided by government standards and the Web Content Accessibility Guidelines WCAG are widely accepted as the international standard for accessibility on the web.',
      description2:
        "Whilst we aim to make this website accessible to all users and achieve a conformance level 'AAA' we continually work with stakeholders to ensure that conformance level 'A' is adhered to as a minimum.",
      tip1: 'Tip! If you experience any accessibility issue on this site or have any comment, please',

      tip2: 'Tip! There are many accessibility features on devices, which can be found on links such as',
      textSizetip: 'Tip! Click increase text size by 25% (e.g. 16px to 20px)',
      contactUs: 'contact us',
      adjustTextSize: 'Adjust Text Size',
      appleAccessibility: 'Apple accessability features',
      androidAccessibility: 'Android accessibility',
      internetExplorerTitle: 'Internet Explorer',
      internetExplorerDescription:
        'Go to “View” on the menu bar - Select text size / zoom',
      firefoxTitle: 'Firefox',
      firefoxDescription:
        'Go to “View” on the menu bar - Select text size / zoom. Alternatively hold down the “Ctrl” button on your keyboard and press the plus (+) key to increase text size. To reduce the latter hold down the “Ctrl” button and press the minus (-) key. Please note that the above settings may differ depending on the browser version.',
      textToSpeechTitle: 'Text To Speech',
      textToSpeechDescription:
        'Many computers and mobile devices today have built in text-to-speech software. Here are guides for each of the major browsers and devices:',
      chromeTitle: 'Chrome',
      googleSpeak: 'Click here to download Google Speak',
      addSpeakButton: 'and select the Add to Chrome button',
      windowsEdgeTitle: 'Windows Edge',
      windowsEdgeDescription:
        'Open the Edge browser and then click on Read Aloud Option or on your keyboard press Ctrl + Shift + U',
      androidApps: 'Android Apps',
      downloadReadAloud: 'Click here to download Read Aloud',
      forAndroid: 'for Android phones or tablets',
      appleTitle: 'Apple',
      downloadVoiceAloud:
        'Click here to download Voice Aloud Reader for Apple devices.',
      searchVoiceAloudReader:
        'Choose your device when you download. Or go to your Apple App store and search Voice Aloud Reader',
      changeFont: 'Change font',
    },
  },
};

export default common;
